<template>
  <Modal
    name="update_phone"
    size="lg"
    title="Внимание!"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="modal__text">Для начисления гарантированного приза в&nbsp;виде 500 баллов <br class="hidden-xs">на&nbsp;счет Х5&nbsp;Клуба, укажите номер мобильного телефона</div>
      <EnterPhoneForm></EnterPhoneForm>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

import EnterPhoneForm from "@/components/form/EnterPhoneForm.vue";

export default {
  components: {
    Modal,
    EnterPhoneForm,
  },
  data: function () {
    return {};
  },

  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>

<style lang="scss" scoped></style>
