<template>
  <form class="modal__form enter-phone-form">
    <inputText
      :key="phoneIndex"
      label="Номер телефона"
      placeholder="+Х(ХХХ) ХХХ-ХХ-ХХ"
      :field="phone"
      type="tel"
      :mask="'+#(###) ###-##-##'"
      class="_centered-text"
      @input="phone = $event"
      :class="{ error: validationStatus($v.phone) || errorMessage.phone }"
      :error="
        (!$v.phone.required && $v.phone.$error ? 'Обязательное поле' : '') ||
        errorMessage.phone
      "
    />

    <div class="recover__footer">
      <button
        type="button"
        class="btn btn--primary"
        @click="updateProfile()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Подтвердить<img
          src="../../assets/img/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
    </div>
  </form>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import { required } from "vuelidate/lib/validators";

export default {
  name: "CodeRegForm",
  data() {
    return {
      ready: false,
      phoneIndex: 1,
      phone: null,

      errorMessage: {
        phone: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    phone: { required },
  },

  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        phone: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    showAuthorization() {
      this.$modal.hide("recover");
      this.$modal.show("authorization");
    },
    updateProfile() {
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        this.$store
          .dispatch("UpdateProfile", {
            phone: this.phone,
            rules1: 1,
          })
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.phone) {
                [this.errorMessage.phone] = r.error.phone;
                fieldsError = true;
              }
              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! ",
                  list: Object.values(r.error),
                });
              }
            } else {
              this.submitStatus = null;
              this.$modal.hide("update_phone");
              this.$modal.show("common_success", {
                title: "Спасибо!",
                text: "Номер телефона успешно обновлен.",
              });
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  mounted() {
    this.phone = this.$store.getters.user.profile.phone;
    this.phoneIndex++;
  },
  components: {
    inputText,
  },
};
</script>

<style lang="scss" scoped>
.enter-phone-form {
  margin-bottom: rem(24px);
  @media (min-width: $md) {
    margin-bottom: rem(30px);
  }
}
</style>
